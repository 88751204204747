.side-menu-container .ant-menu-item {
  display: flex;
  align-items: center;
  height: 60px;
}

.side-menu-container.ant-menu-light .ant-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.104);
}
.side-menu-container.ant-menu-light
  .ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: rgba(0, 0, 0, 0.104);
}

.side-menu-container.ant-menu-light .ant-menu-item-selected {
  background-color: #fafafa;
  border-right: 5px solid #ae7575;
}

.horizontal-list-row::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  display: block;
}
.horizontal-list-row::-webkit-scrollbar-track {
  background: inherit;
}
.horizontal-list-row::-webkit-scrollbar-thumb {
  background-color: rgb(200, 200, 200);
  border-radius: 10px;
  visibility: visible;
}
.horizontal-list-row:hover::-webkit-scrollbar-thumb {
  background-color: rgb(168, 168, 168);
}

.educational-card .ant-card-body {
  padding: 0;
}
