.items-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.items-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.selected {
  border-top: 4px solid rgb(44, 56, 59);
}

.selected::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  border: 7px solid;
  border-color: rgb(44, 56, 59) transparent transparent transparent;
}

.selected::before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  border: 7px solid;
  border-color: rgb(44, 56, 59) transparent transparent transparent;
}

.timeline-open-header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-header {
  cursor: pointer;
  padding: 10px 0px;
}

.timeline-header-container {
  height: 10px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.timeline-header-inner-container {
  display: flex;
  align-items: center;
}

.timeline-header-text {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #343e47;
}

.timeline-header-text-panel-header {
  width: 100%;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #343e47;
}

.collapse {
  border-radius: 0;
}

.collapse.ant-collapse.ant-collapse-icon-position-start
  .ant-collapse-item.ant-collapse-no-arrow {
  border-radius: 0;
}

.timeline-panel-container
  .ant-collapse-content.ant-collapse-content-active
  div.ant-collapse-content-box {
  padding: 0;
}

.timeline-text {
  font-size: 15px;
  padding: 16px;
}

.text-header-prefix {
  font-family: "SF Pro Display";
  font-weight: 700;
  font-size: 15px;
}

.collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

div.ant-carousel
  div.slick-slider.carousel-container.slick-initialized
  ul.slick-dots.slick-dots-bottom {
  width: 100px;
  margin-left: 20px;
  bottom: -5px;
  display: flex;
  justify-content: flex-start;
  /* background-color: red; */
}

div.ant-carousel
  div.slick-slider.carousel-container.slick-initialized
  ul.slick-dots.slick-dots-bottom
  li
  button {
  width: 11px;
  height: 10px;
  background-color: #343e47;
  border-radius: 50%;
}

div.ant-carousel
  div.slick-slider.carousel-container.slick-initialized
  ul.slick-dots
  li.slick-active {
  width: 16px;
}

.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 85vh;
  /* margin-bottom: 5%; */
}

.custom-form-button {
  margin-bottom: 10px;
}

.custom-form-item {
  margin-bottom: 20px !important;
}
.custom-input {
  min-height: 40px;
}
.ant-form-item-explain-error {
  color: darkred !important;
  line-height: 25px;
}
.custom-form-item .ant-input-affix-wrapper {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border: 1px solid white;
}
.custom-form-item .ant-input {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border: 1px solid white;
}
.ant-form-item .ant-form-item-explain-error {
  max-width: 300px;
}
.custom-input > .ant-input {
  color: #3d3d3d;
  max-width: 310px;
  border-color: white;
  border-width: 1;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0) !important;
}
.error-message-text {
  margin: -10px 0px 5px 0px;
  color: darkred;
  max-width: 300px;
}
.header-icon {
  position: absolute;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
}
.header-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* margin-bottom: 5%; */
}
.back-button {
  position: absolute;
  left: 5%;
  top: 20px;
  font-size: 24px;
  background-color: rgba(236, 236, 236, 0.65);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header-page-title-with-button {
  font-family: "Corner Deli T Regular";
  font-size: 90px;
  color: white;
}

.header-page-title {
  font-family: "Corner Deli T Regular";
  font-size: 90px;
  color: white;
  margin-bottom: 5%;
}

.form-title {
  font-family: "circular-std";
  font-size: 50px;
  color: black;
  margin-bottom: 25px;
}

.login-form-button {
  display: block;
  width: 300px;
  height: 40px;
  font-size: 20px;
  border-radius: 10px;
  min-width: 165px;
  font-weight: "bold";
  background-color: #3d3d3d;
  font-family: "circular-std !important";
}

.login-form-button-text {
  margin-bottom: 2px;
  text-decoration: none;
  /* font-family: 'circular-std !important';  */
}

.register-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-family: "circular-std !important";
  font-size: 18px;
}
.register-text-link {
  font-size: 19px;
  font-family: "circular-std";
  text-decoration-line: none;
  margin-left: 5px;
}

.feedback-page-title {
  color: #000;
  text-align: center;
  font-family: "circular-std";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
}

.feedback-page-message {
  color: #3d3d3d;
  text-align: center;
  font-family: "circular-std";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  margin-top: 15px;
  width: 50%;
}

.feedback-page-back-to-login {
  margin-top: 5%;
}
.feedback-hint-message {
  color: #3d3d3d;
  text-align: center;
  font-family: "circular-std";
  font-size: 19px;
  margin-top: 10px;
}
@media (min-width: 768px) {
  .form-container {
    text-align: center;
    backdrop-filter: blur(10px);
    position: relative;
    background: linear-gradient(
      to right,
      rgba(236, 236, 236, 0.3) 37.5%,
      rgba(236, 236, 236, 0.2) 52.5%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 810px;
    min-width: 600px;
    height: 400px;
    border-radius: 20px;
  }
  .footer-container {
    background-color: #3d3d3d;
    min-height: 15vh;
    position: relative;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .footer-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .footer-content-2 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-size: 20px;
  }
  .footer-content-2-text {
    color: white;
    font-family: "circular-std";
    margin-left: 50px;
  }
  .footer-img {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .header-page-title {
    font-size: 60px;
    margin-bottom: 40px;
  }

  .custom-form-item {
    width: 100%;
  }
  .form-title {
    font-size: 36px;
  }
  .form-container {
    text-align: center;
    backdrop-filter: blur(10px);
    position: relative;
    background: linear-gradient(
      to right,
      rgba(236, 236, 236, 0.3) 37.5%,
      rgba(236, 236, 236, 0.2) 52.5%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    height: 420px;
    border-radius: 20px;
  }

  .login-form-button {
    width: 250px;
    min-width: 165px;
    font-size: 16px;
  }
  .register-text {
    font-size: 10px;
  }
  .register-text-link {
    font-size: 12px;
  }
  .feedback-page-title {
    font-size: 36px;
    padding: 5px;
  }
  .feedback-page-message {
    font-size: 15px;
  }
  .back-button {
    left: 5%;
    top: 15px;
    margin-bottom: 40px;
    width: 40px;
    height: 40px;
  }
  .footer-container {
    width: 100%;
    min-height: 15vh;
    background-color: #3d3d3d;
  }
  .footer-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-top: 20px;
  }
  .footer-content-2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
  }
  .footer-content-2-text {
    color: white;
    font-family: "circular-std";
    font-size: 12px;
    margin-left: 0px;
  }
  .footer-img {
    width: 90px;
  }
  .feedback-hint-message {
    padding: 0px 20px;
    font-size: 11px;
  }
  .ant-form-item .ant-form-item-explain-error {
    max-width: 250px;
  }
  .error-message-text {
    max-width: 250px;
  }
}
@media (max-height: 700px) {
  .main-container {
    margin-bottom: 5%;
  }
}
