.ant-spin-nested-loading .ant-spin-blur {
  opacity: 1;
  background-color: rgb(204 204 204 / 50%);
}

.initial-spinner.ant-spin.ant-spin-lg.ant-spin-spinning.ant-spin-show-text {
  z-index: 11;
}

.initial-spinner.ant-spin.ant-spin-lg.ant-spin-spinning
  span.ant-spin-dot.ant-spin-dot-spin {
  font-size: 41px;
}

.initial-spinner.ant-spin.ant-spin-lg.ant-spin-spinning div.ant-spin-text {
  padding-top: 24px;
  color: black;
  font-size: 19px;
}

.initial-spinner.ant-spin.ant-spin-lg.ant-spin-spinning
  span.ant-spin-dot.ant-spin-dot-spin
  i {
  background-color: black;
  width: 19px;
  height: 19px;
}

.spinner.ant-spin.ant-spin-lg.ant-spin-spinning
  span.ant-spin-dot.ant-spin-dot-spin {
  font-size: 41px;
}

.spinner.ant-spin.ant-spin-lg.ant-spin-spinning
  span.ant-spin-dot.ant-spin-dot-spin
  i {
  background-color: white;
  width: 19px;
  height: 19px;
}
